import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import React from 'react'
import { getRegularDate } from 'utils/time.util';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { minWidth } from '@mui/system';

const Table = ({
  rows,
  getWorkCitedDocument,
  setWorkCitedName,
  setWorkCitedFormatStyle,
  setDeleteId,
  setDeletePopup,
}) => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const theme = useTheme();
  const { id } = useParams();

  const handleEdit = (params, workcited_id) => {
    const row = params.row;
    setWorkCitedName(row.name_chr);
    setWorkCitedFormatStyle(row.formatStyle_chr);
    navigate(`/collections/${id}/work-cited/edit/${workcited_id}`);
  };

  const columns = [
    {
      field: "lastUpdatedDateTime_dtm",
      resizable: false,
      headerName: "Last Modified",
      flex: 0.7,
      cellClassName: "ellipsisCell",
      type: "date",
      valueGetter: (value, row, column, apiRef) => {
        return value ? new Date(value) : null;
      },
      renderCell: (params) => {
        const date = params.value ? getRegularDate(params.value) : "-";

        return (
          <Typography
            variant="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              cursor: "pointer",
            }}
          >
            {date ? date : "-"}
          </Typography>
        );
      },
    },
    {
      field: "name_chr",
      headerName: "Name",
      resizable: false,
      flex: 3,
      cellClassName: "ellipsisCell",
      renderCell: (params) => (
        <Typography
          fontSize={"1.3rem"}
          variant="inherit"
          noWrap
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {params.value ? params.value : "-"}
        </Typography>
      ),
    },
    {
      field: "formatStyle_chr",
      headerName: "Style",
      flex: 0.7,
      resizable: false,
      cellClassName: "ellipsisCell",
      renderCell: (params) => (
        <Typography
          fontSize={"1.3rem"}
          variant="inherit"
          noWrap
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {params.value
            ? params.value === "apa"
              ? "APA"
              : params.value === "modern-language-association"
              ? "MLA"
              : "-"
            : "-"}
        </Typography>
      ),
    },
    {
      field: "citationCount",
      headerName: "Citations",
      flex: 0.7,
      resizable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "rowNum",
      headerName: "Options",
      flex: 1,
      resizable: false,
      renderHeader: () => <Box sx={{ visibility: "hidden" }}></Box>,
      renderCell: (params) => {
        const workcited_id = params.row.workCitedId_ids;
        return (
          <Stack mt={0.5} direction="row" gap={1}>
            <IconButton onClick={() => getWorkCitedDocument(workcited_id)}>
              <FileDownloadOutlinedIcon />
            </IconButton>
            <IconButton>
              <EditOutlinedIcon
                onClick={() => handleEdit(params, workcited_id)}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleteId(workcited_id);
                setDeletePopup(true);
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <DataGrid
        apiRef={apiRef}
        disableColumnMenu={false}
        rows={rows}
        columns={columns}
        pageSize={5}
        sx={{
          border: "none",
          width: "100%",
          // margin: "0 auto",
          minWidth: "900px",
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
        }}
        getRowId={(row) => row.createdDateTime_dtm}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdDateTime_dtm", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
};

export default Table