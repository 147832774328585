import { useSelector } from "react-redux";
import { searchAPI } from "services/apis.service";

export function CategoryService(axiosInstance) {
  const axios = axiosInstance;
  const { user } = useSelector((state) => state.auth);

  async function getCategories(userId) {
    try {
      const { data } = await axios.post(
        searchAPI.getCategories,
        {
          callingUserId: userId,
          userId,
          includeDeletedRecords: 0,
        },
        { cache: false }
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function updateCategory(categories) {
    const { data } = await axiosInstance.post(
      searchAPI.updateSidebar,
      {
        listOfCategories: categories,
        callingUserId: user.userId,
        userId: user.userId,
      },
      { cache: false }
    );
    return data;
  }

  async function getAcademiaSearchList(userId) {
    try {
      const { data } = await axios.post(searchAPI.academiaSearchList, {
        userId,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function getDynamicCategories(search, urls) {
      const { data } = await axios.post(searchAPI.getDynamicCategories, {
        searchTerm: search,
        urls: urls,
      });
      return data;
  }

  return {
    getCategories,
    getAcademiaSearchList,
    updateCategory,
    getDynamicCategories,
  };
}
