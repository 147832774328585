import { Box, Button, MenuItem, Popover, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { setIsBookmarkSidebar, setSubPopup } from 'context/features/dataSlice';
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const BookmarkHeader = ({
    setSelectedCollectionOption, 
    selectedCollectionOption
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isPopoverOpen = Boolean(anchorEl);
    const dispatch = useDispatch();
    const {id} = useParams();

    const theme = useTheme();
    const navigate = useNavigate();

    const handleSelectBookmarkOption = (option) => {
        setSelectedCollectionOption(option);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const getBookmarkSxProps = (option) => ({
      fontStyle: "italic",
      cursor: "pointer",
      textDecoration:
        selectedCollectionOption === option ? "underline" : "none",
      textDecorationThickness: "2px",
      textUnderlineOffset: "8px",
      color:
        selectedCollectionOption === option
          ? theme.palette.primary.main
          : "#696b6c",
    });

    const handlePopoverOpen = (e) => {
        setAnchorEl(e.currentTarget)
    }

  return (
    <Stack my={2} direction="row" alignItems="center">
      <Stack mb="8px" direction="row" justifyContent="center" gap={3}>
        <Typography
          variant="h5"
          sx={getBookmarkSxProps("bookmarks")}
          onClick={() => {
            handleSelectBookmarkOption("bookmarks");
          }}
        >
          Bookmarks
        </Typography>
        <Typography
          variant="h5"
          sx={getBookmarkSxProps("workcited")}
          onClick={() => {
            handleSelectBookmarkOption("workcited");
            navigate(`/collections/${id}/work-cited`);
          }}
        >
          Works Cited
        </Typography>
      </Stack>
      <Box>
        <Button
          size="large"
          startIcon={<AddIcon />}
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handlePopoverOpen}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{ml: 2}}
        >
          Create
        </Button>
        <Popover
          anchorEl={anchorEl}
          open={isPopoverOpen}
          onClose={handlePopoverClose}
        >
          <MenuItem
            sx={{
              p: 2,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
            onClick={() => {
              dispatch(setIsBookmarkSidebar(true));
              handlePopoverClose();
            }}
          >
            <Typography variant="body1">Create External Bookmark</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => navigate(`/collections/${id}/work-cited/create`)}
            sx={{
              p: 2,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
          >
            <Typography variant="body1"> Create Works Cited</Typography>
          </MenuItem>
          <MenuItem
          onClick={() => {
            dispatch(setSubPopup(true));
            handlePopoverClose();
          }}
            sx={{
              p: 2,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
          >
            <Typography variant="body1">{`Create Subfolder`}</Typography>
          </MenuItem>
        </Popover>
      </Box>
    </Stack>
  );
}

export default BookmarkHeader