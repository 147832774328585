import { Box, SvgIcon, Tooltip, Typography, useTheme } from "@mui/material";
import { DataGrid, GridRow, useGridApiRef } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { getRegularDate } from "utils/time.util";
import { ReactComponent as citationLogo } from "assets/icons/citation-generator-icon.svg";
import { setCollectionId, setCollectionSourceId, setIsCitationAvailable, setIsSidebarOpen, setSelectedBookmarkOption, setSummaryInfoData } from "context/features/dataSlice";
import { useDispatch } from "react-redux";


const CollectionSourceTable = ({
  rows,
  collectionSourceIds,
  setCollectionSourceIds,
  workCitedFormatStyle,
}) => {
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();

  const theme = useTheme();

  const getTooltipTitle = (row) => {
    if (row.isCitationAvailable_ysn === false) {
      return "Missing Citation";
    } else if (
      row.doesCitationStyleMatch_ysn === false ||
      row.styles_chr !== workCitedFormatStyle
    ) {
      return "Citation Format Does Not Match";
    }
  };

  const handleSidebarOpen = (item, option) => {
    dispatch(setCollectionSourceId(item.collectionSourceID_ids));
    dispatch(setSelectedBookmarkOption(option));
    dispatch(
      setSummaryInfoData({
        publisher: item.sourceName_chr,
        title: item.title_chr,
        published_date: item.sourcePublishedDateTime_dtm,
        url: item.sourceUrl_chr,
      })
    );
    dispatch(setCollectionId(item.collectionID_lng));
    dispatch(setIsCitationAvailable(item.isCitationAvailable_ysn));
    dispatch(setIsSidebarOpen(true));
  };

  const columns = [
    {
      field: "title_chr",
      headerName: "Title",
      resizable: false,
      flex: 2,
      cellClassName: "ellipsisCell",
      renderCell: (params) => {
        return (
          <Tooltip title={getTooltipTitle(params.row)} placement="top">
            <Typography
              variant="inherit"
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleSidebarOpen(params.row, "citation");
              }}
            >
              {params.value ? params.value : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "sourceName_chr",
      headerName: "Source",
      resizable: false,
      flex: 1,
      cellClassName: "ellipsisCell",
      renderCell: (params) => {
        return (
          <Tooltip title={getTooltipTitle(params.row)} placement="top">
            <Typography
              variant="inherit"
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleSidebarOpen(params.row, "citation");
              }}
            >
              {params.value ? params.value : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "sourcePublishedDateTime_dtm",
      headerName: "Pub Date",
      resizable: false,
      flex: 1,
      cellClassName: "ellipsisCell",
      type: "date",
      valueGetter: (value, row, column, apiRef) => {
        return value ? new Date(value) : null;
      },
      renderCell: (params) => {
        const date = params.value ? getRegularDate(params.value) : "-";

        return (
          <Tooltip title={getTooltipTitle(params.row)} placement="top">
            <Typography
              variant="inherit"
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleSidebarOpen(params.row, "citation");
              }}
            >
              {date ? date : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "author_chr",
      headerName: "Author(s)",
      resizable: false,
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={getTooltipTitle(params.row)} placement="top">
          <Typography
            variant="inherit"
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleSidebarOpen(params.row, "citation");
            }}
          >
            {params?.value?.trim()
              ? params.value
                ? params.value.trim()
                : " - "
              : "-"}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "isCitationAvailable_ysn",
      headerName: "Citation",
      resizable: false,
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={getTooltipTitle(params.row)} placement="top">
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleSidebarOpen(params.row, "citation");
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              cursor: "pointer",
              "&:hover > svg": {
                fill: params.value
                  ? theme.palette.primary.dark
                  : theme.palette.mode === "dark"
                  ? theme.palette.grey[300]
                  : theme.palette.grey[700],
              },
            }}
          >
            <SvgIcon
              component={citationLogo}
              inheritViewBox
              sx={{
                fill: params.value
                  ? theme.palette.primary.main
                  : theme.palette.mode === "dark"
                  ? theme.palette.grey[800]
                  : theme.palette.grey[200],
                height: "1.5rem", // Adjusted height for alignment
                width: "1.5rem", // Adjusted width for alignment
              }}
            />
          </Box>
        </Tooltip>
      ),
    },
  ];

  const getRowClassName = (params) => {
    if (workCitedFormatStyle === ""){
      return "";
    }
      if (params.row.isCitationAvailable_ysn === false) {
        return "greyRow";
      } else if (
        params.row.doesCitationStyleMatch_ysn === false ||
        params.row.styles_chr !== workCitedFormatStyle
      ) {
        return "orange";
      }
    return "";
        }

    const checkRowSelectable = (params) => {
       return params.row.styles_chr === workCitedFormatStyle &&
          (params.row.isCitationAvailable_ysn ||
            params.row.doesCitationStyleMatch_ysn)
    }

  useEffect(() => {
    const selectedRowIds =
      rows.length > 0
        ? rows
            .filter((row) => row.isSelected_ysn)
            .map((row) => row.collectionSourceID_ids)
        : [];
    apiRef.current.setRowSelectionModel(selectedRowIds);
    setCollectionSourceIds(selectedRowIds);
  }, [apiRef, rows, workCitedFormatStyle]);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <DataGrid
        apiRef={apiRef}
        disableColumnMenu={false}
        rows={rows}
        columns={columns}
        checkboxSelection
        pageSize={5}
        onRowSelectionModelChange={(selectedIds) => {
          setCollectionSourceIds(selectedIds);
        }}
        sx={{
          border: "none",
          width: "100%",
          // margin: "0 auto",
          minWidth: {sm: "900px", md: "unset"},
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .orange": {
            backgroundColor: "rgba(255, 165, 0, 0.3)",
          },
          "& .orange:hover": {
            backgroundColor: "rgba(255, 165, 0, 0.3)",
          },
          "& .greyRow": {
            color: "grey",
          },
          "& .greyRow:hover": {
            color: "grey",
            backgroundColor: "inherit",
          },
        }}
        getRowId={(row) => row.collectionSourceID_ids}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdDateTime_dtm", sort: "desc" }],
          },
        }}
        isRowSelectable={(params) => checkRowSelectable(params)}
        getRowClassName={(params) => getRowClassName(params)}
      />
    </Box>
  );
};

export default CollectionSourceTable;
