import React, { useEffect, useState } from "react";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { getFormattedDate } from "utils/time.util";
import { MoreHoriz } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useLocalStorage, { writeStorage } from "@rehooks/local-storage";
import ColumnsSection from "features/Collections/components/ColumnSection";
import { useSelector } from "react-redux";

function Table({ publicCollections, setCurrentPublicCollection }) {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const theme = useTheme();

  const defaultVisibilityModel = {
    lastUpdatedDateTime_dtm: true,
    collectionName_chr: true,
    userName_chr: true,
    publicCollectionSource_Count_int: true,
  };

  const { user } = useSelector((state) => state.auth);

  const [visibilityModel, setVisibilityModel] = useLocalStorage(
    `public-columnvisibility-${user.userId}`,
    defaultVisibilityModel
  );

  const [columnWidths, setColumnWidths] = useLocalStorage(
    `recent-columnWidths-${user.userId}`,
    {}
  );

  const handleColumnResize = (params) => {
    const updatedWidths = {
      ...columnWidths,
      [params.colDef.field]: params.width,
    };
    setColumnWidths(updatedWidths);
    writeStorage(`recent-columnWidths-${user.userId}`, updatedWidths);
  };

  const handleVisibilityChange = (newVisibilityModel) => {
    const updatedVisibility = {
      ...visibilityModel,
      ...newVisibilityModel,
    };

    setVisibilityModel(updatedVisibility);
    writeStorage(`public-columnvisibility-${user.userId}`, updatedVisibility);
  };

  useEffect(() => {
    if (!visibilityModel || Object.keys(visibilityModel).length === 0) {
      setVisibilityModel(defaultVisibilityModel);
      writeStorage(
        `public-columnvisibility-${user.userId}`,
        defaultVisibilityModel
      );
      apiRef.current.setColumnVisibilityModel(defaultVisibilityModel);
    } else {
      apiRef.current.setColumnVisibilityModel(visibilityModel);
    }
  }, []);

  useEffect(() => {
    const savedWidths = columnWidths;

    if (savedWidths) {
      Object.entries(savedWidths).forEach(([field, width]) => {
        apiRef.current.setColumnWidth(field, width);
      });
    }
  }, [apiRef, columnWidths]);

  const columns = [
    {
      field: "lastUpdatedDateTime_dtm",
      flex: columnWidths["lastUpdatedDateTime_dtm"] ? 0 : 1,
      width: columnWidths["lastUpdatedDateTime_dtm"],
      headerName: "Updated",
      sortable: true,
      type: "date",
      valueGetter: (value, row, column, apiRef) => {
        return value ? new Date(value) : null;
      },
      renderCell: (params) => (
        <Typography
          variant="body3"
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {getFormattedDate(params.value)}
        </Typography>
      ),
    },
    {
      field: "collectionName_chr",
      headerName: "Collection Name",
      flex: columnWidths["collectionName_chr"] ? 0 : 2,
      width: columnWidths["collectionName_chr"],
      sortable: true,
      renderCell: (params) => (
        <Typography
          onClick={() => {
            setCurrentPublicCollection(params.row);
            navigate(
              `/collections/public/${params.row.publicCollectionID_chr}`
            );
          }}
          variant="inherit"
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            fontSize: "1.4rem",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "userName_chr",
      headerName: "Owner",
      flex: columnWidths["userName_chr"] ? 0 : 1,
      width: columnWidths["userName_chr"],
      sortable: false,
      renderCell: (params) => (
        <Typography
          variant="inherit"
          sx={{ display: "flex", alignItems: "center", height: "100%" }}
          fontSize={"1.4rem"}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "publicCollectionSource_Count_int",
      headerName: "Source Count",
      flex: columnWidths["publicCollectionSource_Count_int"] ? 0 : 1,
      width: columnWidths["publicCollectionSource_Count_int"],
      sortable: true,
      renderCell: (params) => (
        <Typography
          sx={{ display: "flex", alignItems: "center", height: "100%" }}
          fontSize={"1.4rem"}
          variant="inherit"
        >
          {params.value}
        </Typography>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <ColumnsSection
        visibilityModel={visibilityModel}
        setVisibilityModel={setVisibilityModel}
        columns={columns}
        label={`public-columnvisibility-${user.userId}`}
      />
      <DataGrid
        apiRef={apiRef}
        disableColumnMenu={false}
        rows={publicCollections}
        columns={columns}
        onColumnResize={handleColumnResize}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={handleVisibilityChange}
        pageSize={5}
        sx={{
          border: "none",
          overflow: "hidden",
          width: "100%",
          margin: "0 auto",
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center", // Ensure vertical alignment
            height: "100%", // Ensure cells take full height
          },
          "& .MuiDataGrid-cell:focus-within": {
            backgroundColor: "transparent",
            outline: "none",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
          },
        }}
        getRowId={(row) => row.publicCollectionID_chr}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50]}
        hideFooterSelectedRowCount
        onRowClick={(params) => {
          setCurrentPublicCollection(params.row);
          navigate(`/collections/public/${params.row.publicCollectionID_chr}`);
        }}
      />
    </Box>
  );
}

export default Table;
