import React, { useEffect, useState } from 'react'
import Table from './Table'
import useWorkCited from '../hooks/use-work-cited.hook'
import { useParams } from 'react-router-dom';
import DeleteWorkCitedPopup from 'features/Collections/DeleteWorkCited';
import WorkCited from '..';
import { Box, Typography } from '@mui/material';

const MainPage = ({setWorkCitedName, setWorkCitedFormatStyle}) => {
  const [worksCited, setWorksCited] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { getWorkCited, getWorkCitedDocument, deleteWorkCitedDocument } =
    useWorkCited(worksCited, setWorksCited);

    const {id} = useParams();

    const deleteBookmarkSource = () => {
      deleteWorkCitedDocument(deleteId);
      setDeletePopup(false);
    }

    useEffect(() => {
      getWorkCited();
    }, [id])

  return (
    <React.Fragment>
      {(worksCited.length === 0 || !WorkCited) ?
      <Box sx={{mt: 4, display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Typography color="grey">Click the create button to get started with a new Works Cited.</Typography> 
      </Box> :
      <Table
        rows={worksCited}
        getWorkCitedDocument={getWorkCitedDocument}
        setWorkCitedName={setWorkCitedName}
        setWorkCitedFormatStyle={setWorkCitedFormatStyle}
        setDeleteId={setDeleteId}
        setDeletePopup={setDeletePopup}
      />
}
      {deletePopup && (
        <DeleteWorkCitedPopup
          deletePopup={deletePopup}
          setDeletePopup={setDeletePopup}
          deleteBookmarkSource={deleteBookmarkSource}
        />
      )}
    </React.Fragment>
  );
}

export default MainPage