import { Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CategorySection } from "./components/section-category";
import LoaderPage from "components/layout/LoaderPage";
import useCategoryData from "./hooks/use-category-data.hook";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RelevantSection from "./components/relevant-section";
import { AllCategoryUrls, IsCategoryFetched } from "./data/default-states-category";

export default function News() {
  const [loading, setLoading] = useState(true);
  const [categoriesFetched, setCategoriesFetched] = useState(IsCategoryFetched);
  const [categoriesUrl, setCategoriesUrl] = useState(AllCategoryUrls);
  const [categorySorted, setCategorySorted] = useState(false);
  const { fetchNewsCategoryData, getDynamicCategoriesFromUrl } =
    useCategoryData(setLoading, setCategoriesFetched);

  const { categories } = useSelector(
    (state) => state.data
  );

  const { search, source } = useParams();
  const location = useLocation();

  const getQueryParams = (query) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const pubDate = queryParams.get("pubDate");
  

  function checkedSource(source) {
    return source == null ? "categories" : source;
  }

  const checkCategoriesFetched = useMemo(() => {
    if (categoriesFetched) {
      return Object.values(categoriesFetched).every((value) => value === true);
    }
    return false;
  }, [categoriesUrl]);

  useEffect(() => {
    setCategoriesFetched(IsCategoryFetched);
    setCategoriesUrl(AllCategoryUrls);
    setCategorySorted(false);
  }, [search]);

  useEffect(() => {
    if(categories.length > 0){
    const hidden =  categories.reduce((acc, category) => {
      if (category.isHidden_ysn) {
        acc[category.categoryName_chr] = true;
      }
      return acc;
    }, {});
    setCategoriesFetched((prev) => ({
      ...prev, ...hidden
    }))
  }
  }, [categories, search]);


  useEffect(() => {
    fetchNewsCategoryData();
  }, []);

  useEffect(() => {
    if(checkCategoriesFetched){
      getDynamicCategoriesFromUrl(
        Object.values(categoriesUrl),
        setCategorySorted
      );
    }
  }, [checkCategoriesFetched]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoaderPage />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <RelevantSection />
      {categories.map((category) => {
        return (
          <Box key={`${checkedSource(source)}-${category.category_id}`}>
            <CategorySection
              query={search}
              category={category}
              source={checkedSource(source)}
              pubDate={pubDate}
              checkCategoriesFetched={checkCategoriesFetched}
              setCategoriesFetched={setCategoriesFetched}
              setCategoriesUrl={setCategoriesUrl}
              categorySorted={source === "rating" ? true : categorySorted}
            />
          </Box>
        );
      })}
    </React.Fragment>
  );
}
