import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ImInfo } from "react-icons/im";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SummaryBox from "features/Bookmarks/components/Summarize/Box";
import ReactMarkdown from "react-markdown";

import {
  setArticleSummary,
  setIsBookmarkSidebar,
  setIsSummaryAvailable,
} from "context/features/dataSlice";
import { InfoOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { levels } from "features/Bookmarks/data/levels";

function TooltipContent() {
  return (
    <React.Fragment>
      <Typography variant="caption" component="p">
        We use a 3rd party generative language model to rewrite your article at
        the desired reading comprehension level. The first step we perform is
        fetching the entire contents of the article. If we cannot perform this
        step, the feature will provide you with a failure notice. Then, we send
        the article text to the 3rd party model using a “few-shot instruction”
        framework to ensure the article is generated at the chosen reading
        level. At no point in this process do we send any of your personal user
        data to the 3rd party model. The only data that is sent is the article
        text (or the text you provided if you’re using the custom tool).
      </Typography>
    </React.Fragment>
  );
}

const GeneratedText = ({
  markdownSummary,
  setmarkdownSummary,
  changeTooltip,
  handleCopy,
  handleMouseOut,
  htmlSummary,
  sethtmlSummary,
  handleSave,
  level,
}) => {
  const theme = useTheme();
  const [editing, setEditing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const dispatch = useDispatch();

  const tooltipTitle = changeTooltip
    ? "Copied to clipboard"
    : "Copy to clipboard";

  const saveSummary = (markdown) => {
    setmarkdownSummary(markdown);
    setIsFocused(false);
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Tooltip placement="right-start" title={<TooltipContent />}>
            <InfoOutlined
              sx={{
                fontSize: theme.typography.subtitle1.fontSize,
              }}
            />
          </Tooltip>
          <Typography variant="caption">How do we generate it?</Typography>
        </Stack>
        <Button
          onClick={() => setmarkdownSummary("")}
          startIcon={<RestartAltIcon />}
          sx={{ backgroundColor: theme.palette.secondary.main, color: "black" }}
        >
          Restart
        </Button>
      </Stack>
      {editing ? (
        <Box sx={{ my: 2 }}>
          <SummaryBox
            editing={editing}
            setEditing={setEditing}
            markdownSummary={markdownSummary}
            htmlSummary={htmlSummary}
            sethtmlSummary={sethtmlSummary}
            saveSummary={saveSummary}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
          />
        </Box>
      ) : (
        <ReactMarkdown>{markdownSummary}</ReactMarkdown>
      )}

      {!editing && (
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            {!editing ? (
              <Button
                startIcon={
                  <EditOutlinedIcon
                    sx={{ color: theme.palette.primary.main }}
                  />
                }
                sx={{
                  mt: "2rem",
                  px: 2,
                  py: 1,
                  backgroundColor: theme.palette.secondary.main,
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditing((edit) => !edit);
                }}
              >
                Edit
              </Button>
            ) : (
              <Button
                startIcon={<CloseIcon />}
                sx={{
                  mt: "2rem",
                  px: 2,
                  py: 1,
                  backgroundColor: theme.palette.primary.main,
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditing((edit) => !edit);
                }}
              >
                Close
              </Button>
            )}
            <Tooltip title={tooltipTitle}>
              <Button
                startIcon={
                  <ContentCopyIcon sx={{ color: theme.palette.primary.main }} />
                }
                sx={{
                  mt: "2rem",
                  px: 2,
                  py: 1,
                  backgroundColor: theme.palette.secondary.main,
                  textTransform: "none",
                }}
                onMouseOut={handleMouseOut}
                onClick={() => {
                  const readinglevel = levels?.filter(
                    (curlevel) => curlevel.level === level
                  )[0]?.value;
                  handleCopy(readinglevel);
                }}
              >
                Copy
              </Button>
            </Tooltip>
          </Stack>
          <Button
            onClick={() => {
              handleSave();
            }}
            sx={{ py: 0 }}
          >
            Copy to Collection
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default GeneratedText;
