import {
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
  ToggleButton,
  ToggleButtonGroup,
  Button,
} from "@mui/material";
import RightSidebar from "components/base/RightDrawer";
import CloseIcon from "@mui/icons-material/Close";
import LanguageIcon from "@mui/icons-material/Language";
import CheckIcon from "@mui/icons-material/Check";
import React, { useEffect, useState } from "react";
import { emailValidation } from "utils/validation.util";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import PublicConfirm from "./PublicConfirm";
import { useSelector } from "react-redux";
import RemoveModal from "./RemovePopup";
import PrivateConfirm from "./PrivateConfirmPopup";
import { getFormattedDate } from "utils/time.util";
import ProfileAvatar from "components/base/ProfileAvatar";
import PublicCollectionDetailCard from "./PublicCollectionDetailCard";
import _ from "lodash";

const AccessSidebar = ({
  accessPopup,
  setAccessPopup,
  getUsersList,
  shareCollectionFolder,
  removeSharedUser,
  collection,
  length,
  makeCollectionPublic,
  handleRemoveAllSharedUsers,
}) => {
  const params = useParams();
  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;
  const [view, setView] = useState(
    collection.isPublic_ysn ? "public" : "private"
  );
  const [users, setUsers] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [remove, setRemove] = useState(false);
  const [removeEmail, setRemoveEmail] = useState([]);
  const [privatePopup, setPrivatePopup] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      if (newView === "public" && users.length > 0) {
        toast.error(
          "A shared collection cannot be made public. Remove all the users first to make it public."
        );
        return;
      } else if (newView === "public") {
        setConfirmPopup(true);
      } else if (newView === "private") {
        setPrivatePopup(true);
      }
    }
  };

  const setUsersList = async () => {
    const data = await getUsersList();
    setUsers(_.filter(data, (singleUser) => singleUser.email !== user.email));
  };

  const handleAddEmail = async () => {
    try {
      if (email === "" || !emailValidation(email)) {
        toast.error("Please enter a valid email address.");
        return;
      }
      await shareCollectionFolder([email]);
      setEmail("");
    } catch (e) {
      console.log(e);
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (email === user.email){
        toast.error(
          "You cannot share the collection with your own email address."
        );
        return;
      } 
      await handleAddEmail();
      await setUsersList();
    }
  };

  const handleDelete = async () => {
    let emails = [email];

    try {
      if (removeEmail === "all") {
        await handleRemoveAllSharedUsers();
        setUsers([]);
      } else {
        await removeSharedUser(params.id, user.userId, removeEmail);
        setUsers((prev) => prev.filter((user) => user.email != removeEmail));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRemove(false);
    }
  };

  useEffect(() => {
    setUsersList();
  }, [accessPopup]);

  return (
    <React.Fragment>
      <RightSidebar open={accessPopup} onClose={() => setAccessPopup(false)}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "secondary.main",
              alignItems: "center",
              p: 3,
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: "2rem", color: "gray" }}
            >
              Manage Access to "{collection.collectionName_chr}"
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setAccessPopup(false)}
              sx={{ p: 0 }}
            >
              <CloseIcon sx={{ color: "#a9baca", height: "2.2rem" }} />
            </IconButton>
          </Box>

          <Box sx={{ p: 2, mt: 2, px: 3 }}>
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ color: "gray", fontWeight: "bold" }}
                >
                  Others with access
                </Typography>
                {users.length !== 0 && (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setRemoveEmail("all");
                      setRemove(true);
                    }}
                  >
                    Remove All
                  </Button>
                )}
              </Stack>
              {users.map((user) => {
                return (
                  <Stack
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    key={user.email}
                    sx={{ mb: 2 }}
                  >
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Stack sx={{ lineHeight: 1.2 }} gap={0}>
                        <Stack gap={0.5} flexDirection="row" alignItems="center">
                          <ProfileAvatar userId={user.userId} />
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "1.6rem",
                            }}
                          >
                            {user.userId ? `@${user.userName}` : user.email}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "grey.600",
                            fontSize: "1.3rem",
                            ml: 3.5
                          }}
                        >
                          {user.userId ? `${user.email}` : ""}
                        </Typography>
                      </Stack>
                    </Box>
                    <Button
                      onClick={() => {
                        setRemoveEmail(user.email);
                        setRemove(true);
                      }}
                      variant="outlined"
                    >
                      {user.userId ? "Remove" : "Uninvite"}
                    </Button>
                  </Stack>
                );
              })}
              {view === "public" ? (
                <Typography variant="body1" color={"#6d6d6d"}>
                  This collection cannot be shared while it’s public. Switch to
                  Private before sharing access with others.
                </Typography>
              ) : (
                <TextField
                  sx={{ width: "100%" }}
                  placeholder="Add people by email..."
                  onKeyDown={handleKeyDown}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}

              <Divider sx={{ my: 4 }} />
            </Stack>

            <Stack gap={2}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: "gray", fontWeight: "bold" }}
              >
                Publish settings
              </Typography>
              <Stack direction="row" gap={7} alignItems={"center"} mb={"1rem"}>
                <ToggleButtonGroup
                  disabled={users.length !== 0}
                  value={view}
                  exclusive
                  onChange={handleViewChange}
                  aria-label="Private/Public Toggle"
                  sx={{
                    "&.Mui-disabled": {
                      pointerEvents: "none", // Ensures the buttons are not clickable
                    },
                  }}
                >
                  <ToggleButton
                    value="private"
                    startIcon={view === "private" ? <CheckIcon /> : null}
                    aria-label="private"
                    sx={{
                      width: "10rem",
                      height: "5rem",
                      textTransform: "none",
                      color: "black",
                      borderRadius: 10,
                      "&.Mui-selected": {
                        backgroundColor: navbarBackground,
                        color: "#125997",
                        fontWeight: "bold",
                      },
                      "&.Mui-disabled": {
                        color: "#93aec0",
                      },
                    }}
                  >
                    {view === "private" && (
                      <CheckIcon sx={{ marginRight: "8px", width: "2rem" }} />
                    )}
                    Private
                  </ToggleButton>
                  <ToggleButton
                    value="public"
                    aria-label="public"
                    sx={{
                      width: "10rem",
                      height: "5rem",
                      textTransform: "none",
                      color: "black",
                      borderRadius: 10,
                      "&.Mui-selected": {
                        backgroundColor: navbarBackground,
                        color: "#125997",
                        fontWeight: "bold",
                      },
                      "&.Mui-disabled": {
                        color: "#93aec0",
                        backgroundColor: "#eff4f8",
                      },
                    }}
                  >
                    {view === "public" && (
                      <CheckIcon sx={{ marginRight: "8px", width: "2rem" }} />
                    )}
                    Public
                  </ToggleButton>
                </ToggleButtonGroup>
                {view !== "public" && (
                  <Typography variant="body1" color="grey">
                    Only you and those you’ve added here can see your
                    collection.
                  </Typography>
                )}
              </Stack>
              {view === "public" && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: 2,
                      backgroundColor: navbarBackground,
                      borderRadius: 1,
                      mt: 1,
                    }}
                  >
                    <PublicCollectionDetailCard
                      collection={collection}
                      length={length}
                    />
                  </Box>
                </Box>
              )}
              {users.length === 0 ? (
                <Typography variant="body1" color="grey">
                  The Public version of your collection does not include notes,
                  summaries, citations, or bookmarks added from URLs.
                </Typography>
              ) : (
                <Typography variant="body1" color="grey">
                  This collection cannot be made public because others have
                  access. Remove All before switching from Private to Public.
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>
      </RightSidebar>
      <PublicConfirm
        makeCollectionPublic={makeCollectionPublic}
        open={confirmPopup}
        onClose={() => setConfirmPopup(false)}
        setView={setView}
        collection={collection}
        length={length}
        view={view}
      />
      <RemoveModal
        removeState={removeEmail}
        isOpen={remove}
        onClose={() => setRemove(false)}
        onConfirm={handleDelete}
      />
      <PrivateConfirm
        makeCollectionPublic={makeCollectionPublic}
        isOpen={privatePopup}
        onClose={() => setPrivatePopup(false)}
        collection={collection}
        setConfirmPopup={setConfirmPopup}
        setView={setView}
      />
    </React.Fragment>
  );
};

export default AccessSidebar;
