import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  Modal,
  useTheme,
  Tooltip,
  Button,
  CardContent,
  Stack,
  CardActions,
  Skeleton,
} from "@mui/material";
import { FiArrowUpRight } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { ImInfo } from "react-icons/im";
import { Link } from "react-router-dom";
import { logEvent } from "services/amplitude.service.js";
import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import useCategoryData from "../hooks/use-category-data.hook";
import CategoryIcon from "./CategoryIcon";
import { fontSize } from "@mui/system";

function TooltipContent({ description }) {
  if (Array.isArray(description)) {
    return (
      <Stack direction="column">
        {description.map((item, index) => (
          <Typography key={`category-desc-${index}`} variant="body1">
            {item}
          </Typography>
        ))}
      </Stack>
    );
  }
  return <Typography variant="body1">{description}</Typography>;
}

export default function SidebarCard({ category, link, categorySorted }) {
  const [open, setOpen] = useState(false);
  // const [bgIcon, setBgIcon] = useState("");
  const theme = useTheme();
  const isMobile = useIsMobile();
  // const { getCategoryIcon } = useCategoryData(() => {});

  // useEffect(() => {
  //   setBgIcon(getCategoryIcon(category.categoryName_chr));
  // }, [category, getCategoryIcon]);

  const handleInfoClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalStyle = {
    display: "flex",
    flexDirection: "column",
    width: "40rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    outline: "none",
    borderRadius: "2rem",
    gap: 1.2,
  };

  const handleClick = () => {
    logEvent("click_category_card", {
      category: category.categoryName_chr,
    });
  };

  if (!categorySorted)
    return (
      <Skeleton
        sx={{
          position: "relative",
          minWidth: { xs: "100%", sm: "23vw", md: "17vw" },
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 1,
        }}
        variant="rectangular"
        height="25vh"
      />
    );

  return (
    <React.Fragment>
      <Card
        elevation={0}
        sx={{
          position: "relative",
          minWidth: { xs: "100%", sm: "23vw", md: "17vw" },
          backgroundColor: theme.palette.secondary.main,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          wordBreak: "break-word",
        }}
      >
        <CategoryIcon
          iconId={category.categoryName_chr}
          sx={{
            fill: theme.palette.secondary.dark,
            position: "absolute",
            fontSize: "30rem",
            right: "-10rem",
            bottom: "-10rem",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 4.4,
            right: 5.4,
            display: "flex",
            zIndex: 10,
            "& > svg": {
              color: theme.palette.secondary.dark,
            },
            "&:hover > svg": {
              color: theme.palette.primary.dark,
            },
          }}
        >
          <Tooltip
            title={
              !isMobile ? (
                <TooltipContent description={category.description_chr} />
              ) : null
            }
            placement="right"
            arrow
          >
            <Box>
              <ImInfo
                onClick={handleInfoClick}
                size={16}
                style={{
                  p: 1,
                }}
              />
            </Box>
          </Tooltip>
        </Box>
        <CardContent>
          <Typography
            component="div"
            key={category}
            fontSize="2.3rem"
            color="primary.dark"
            fontFamily={theme.typography.h3.fontFamily}
            fontWeight={theme.typography.h3.fontWeight}
          >
            {category.categoryName_chr}
          </Typography>
        </CardContent>
        <CardActions onClick={handleClick}>
          <Button
            variant="text"
            size="small"
            to={link}
            component={Link}
            startIcon={<FiArrowUpRight />}
            color="inherit"
          >
            See all
          </Button>
        </CardActions>
      </Card>
      {isMobile && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <ImInfo color="secondary.dark" size={15} />
                <Typography>About this category</Typography>
              </Box>
              <AiOutlineClose
                onClick={handleClose}
                size={18}
                style={{
                  cursor: "pointer",
                  color: theme.palette.secondary.dark,
                }}
              />
            </Box>
            <Box sx={{ display: "block" }}>
              <Typography variant="h4" color="primary.dark">
                {category.categoryName_chr}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: 1 }}>
              <TooltipContent description={category.description_chr} />
            </Box>
          </Box>
        </Modal>
      )}
    </React.Fragment>
  );
}
