import { axiosInstance } from "lib/axios";
import { authAPI, searchAPI } from "services/apis.service";

export const getPublicCollections = async (user) => {
  const { data } = await axiosInstance.post(
    searchAPI.getPublicCollections,
    {
      callingUserId: user.userId,
      pageSize: 25,
    },
    { cache: false }
  );
  return data;
};

export const getPublicCollectionsSource = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.getPublicCollectionsSource,
    {
      callingUserId: user.userId,
      publicCollectionId: id,
    },
    { cache: false }
  );

  return data;
};

export const getPublicCollectionOverview = async (user, id) => {
  const { data } = await axiosInstance.post(
    searchAPI.publicCollectionOverView,
    {
      callingUserId: user.userId,
      publicCollectionId: id,
    },
    { cache: false }
  );

  return data;
};

export const followUnFollowUser = async (user, id, username) => {
  const { data } = await axiosInstance.post(authAPI.followUnFollowUser, {
    callingUserId: user.userId,
    followerUserId: id,
    followingUserName: username,
    followAction: true,
  });

  return data;
};


export const publicCollectionCopy = async (user, id) => {
   const { data } = await axiosInstance.post(searchAPI.copyPublicCollection, {
     callingUserId: user.userId,
     publicCollectionId: id,
     destinationUserID: user.userId,
   }, {cache: false});

   return data;
}
