export const mediaBiasUrl = (rating) => {
  if (rating == "Lean Left") {
    return "https://www.allsides.com/media-bias/left-center";
  } else if (rating == "Left") {
    return "https://www.allsides.com/media-bias/left";
  } else if (rating == "Center") {
    return "https://www.allsides.com/media-bias/center";
  } else if (rating == "Lean Right") {
    return "https://www.allsides.com/media-bias/right-center";
  } else if (rating == "Right") {
    return "https://www.allsides.com/media-bias/right";
  }
  return "";
};