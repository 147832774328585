import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
  Modal,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DeleteWorkCitedPopup = ({
  deletePopup,
  setDeletePopup,
  deleteBookmarkSource,
}) => {
  const theme = useTheme();
  const navbarBackground = theme.palette?.navbar?.main;

  const handleDelete = async () => {
    await deleteBookmarkSource();
  };

  const onClose = () => {
    setDeletePopup(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "50vw", md: "35vw" },
    bgcolor: "background.paper",
    boxShadow: 0,
    borderRadius: 2,
  };

  return (
    <Modal
      open={deletePopup}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box sx={modalStyle}>
        <Box sx={{ p: 4 }}>
          <Typography
            variant="h4"
            component="div"
            color="#406da4"
            fontWeight="600"
          >
            Confirm Deletion
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ mt: 2 }}>
            Are you sure you want to delete this Works Cited?
          </Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: navbarBackground,
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            px: 5,
            py: 3,
            borderRadius: "0 0 20px 20px",
          }}
        >
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{ width: "45%", textTransform: "none", borderColor: "#406da4" }}
          >
            <Typography variant="h6" sx={{ color: "#406da4" }}>
              Cancel
            </Typography>
          </Button>
          <Button
            onClick={handleDelete}
            sx={{
              width: "45%",
              textTransform: "none",
              backgroundColor: "#406da4",
            }}
          >
            <Typography variant="h6">Delete</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteWorkCitedPopup;
