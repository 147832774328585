export const IsCategoryFetched = {
    "Periodicals & Specialty News": false,
    "Think Tanks": false,
    "Global News": false,
    "Academia & Scholarly Resources": false,
    "US Government": false,
    "Intergovernmental Organizations": false,
    "Other Selected Sources": false,
  };

export const AllCategoryUrls = {
  "Periodicals & Specialty News": "",
  "Think Tanks": "",
  "Global News": "",
  "Academia & Scholarly Resources": "",
  "US Government": "",
  "Intergovernmental Organizations": "",
  "Other Selected Sources": "",
};