import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Toolbar,
  AppBar,
  Popover,
  Chip,
  IconButton,
  useTheme,
  Typography,
  SvgIcon,
  Collapse,
  Stack,
  Button,
  Badge,
  Tooltip,
} from "@mui/material";
import {
  ArrowBackOutlined,
  ArrowUpwardRounded,
  Check,
  Close,
} from "@mui/icons-material";

import { useIsMobile } from "hooks/use-breakpoint-checks.hook";
import { useUserData } from "features/User";

import MainNavLink from "components/base/MenuNavLink";
import Logo from "components/layout/Logo";
import ProfileAvatar from "components/base/ProfileAvatar";
import ToolMenuItem from "./ToolMenuItem";

import SummarizerIcon from "assets/icons/SummarizerIcon";
import MisinformationFingerprintIcon from "assets/icons/MisinformationFingerprintsIcon";
import MisinformationSearchIcon from "assets/icons/MisinformationSearchIcon";
import DomainCheckerIcon from "assets/icons/DomainCheckerIcon";
import { ReactComponent as ResearchToolsWhite } from "assets/icons/research-tools-icon-white.svg";
import { ReactComponent as CollectionWhiteLogo } from "assets/icons/collections-icon-white.svg";
//import { ReactComponent as PulseIconLogo } from "assets/icons/pulse-icon-white.svg";
import { initAmplitude, logEvent } from "services/amplitude.service";
import { PrimarySearchBox, useSourceSearch } from "features/SourceSearch";
import { PulseIconLogo } from "assets/icons/PulseIcon";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import { group } from "d3";
import { useGroup } from "features/Group";

export default function MenuAppBar({ isLandingPage = false }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { timeTillExpired, accountType, groupRole } = useUserData();
  const { group } = useGroup();

  const { user } = useSelector((state) => state.auth);

  const isMobile = useIsMobile();
  // const [profilePopup, setProfilePopup] = useState(null);
  const [researchPopup, setResearchPopup] = useState(false);

  const {
    currentSearchTerm,
    currentSource,
    currentCategory,
    currentTimeframe,
    currentSubCategory,
    buildSearchUrl,
    getCategory,
    getSource,
    getSubCategory,
    getTimeframe,
    handleActiveStateChange,
    startDate,
    endDate,
    defaultSource,
    defaultTimeframe,
  } = useSourceSearch();

  // const handleProfileOpen = (event) => {
  //   event.preventDefault();
  //   setProfilePopup(event.currentTarget);
  // };
  // const handleProfileClose = () => {
  //   setProfilePopup(null);
  // };

  function showFilters() {
    return !(
      currentCategory == null &&
      currentSource === defaultSource.key &&
      currentTimeframe === defaultTimeframe.key
    );
  }

  function showAllButton() {
    return !(
      currentSearchTerm == null ||
      currentSearchTerm === "" ||
      currentCategory == null ||
      currentSource === "books" ||
      currentSource === "wiki"
    );
  }

  function handleResearchOpen(event) {
    event.preventDefault();
    setResearchPopup(event.currentTarget);
    logEvent("open_tools_page", {
      click: true,
    });
  }
  function handleResearchClose() {
    setResearchPopup(null);
  }

  function handleClickAll() {
    navigate(
      buildSearchUrl({
        query: currentSearchTerm,
        source: currentSource,
        date: currentTimeframe,
        startDate,
        endDate,
      })
    );
  }

  useEffect(() => {
    initAmplitude(user.userId);
  });

  return (
    <React.Fragment>
      <AppBar
        position={isLandingPage ? "fixed" : "static"}
        sx={{
          backgroundColor: isLandingPage
            ? "transparent"
            : theme.palette.secondary.main,
        }}
        elevation={0}
      >
        <Toolbar
          sx={{
            display: "grid",
            gridTemplateAreas: isMobile
              ? '"logo logo logo promo promo promo" "search search search search search search" "logoChip logoChip filter filter filter filter"'
              : '"logo search search search promo nav" "logoChip filter filter filter . nav"',
            gridTemplateColumns: isMobile
              ? "repeat(6, 1fr)"
              : "repeat(5, 1fr) auto",
            gap: 1,
            position: isMobile ? "unset" : "relative",
          }}
        >
          {accountType === "trial" && (
            <Box
              sx={{
                gridArea: "promo",
                display: "flex",
                justifyContent: "center",
                px: 1,
                pt: 1,
              }}
            >
              <Badge
                badgeContent={timeTillExpired}
                color={
                  timeTillExpired < 4
                    ? "error"
                    : timeTillExpired < 11
                    ? "warning"
                    : "info"
                }
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                max={30}
              >
                <Button
                  variant={isLandingPage ? "contained" : "outlined"}
                  color="primary"
                  LinkComponent={Link}
                  to="/profile/subscription"
                  size="small"
                  endIcon={<ArrowUpwardRounded />}
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    textTransform: "uppercase",
                  }}
                >
                  Upgrade
                </Button>
              </Badge>
            </Box>
          )}
          {group != null &&
            group.isPaymentPending &&
            groupRole === "OrganizationAdmin" && (
              <Box
                sx={{
                  gridArea: "promo",
                  display: "flex",
                  justifyContent: "center",
                  px: 1,
                  pt: 1,
                }}
              >
                <Badge
                  badgeContent={timeTillExpired}
                  color={
                    timeTillExpired < 4
                      ? "error"
                      : timeTillExpired < 11
                      ? "warning"
                      : "info"
                  }
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  max={60}
                >
                  <Button
                    variant={isLandingPage ? "contained" : "outlined"}
                    color="warning"
                    LinkComponent={Link}
                    to="/profile/subscription"
                    size="small"
                    endIcon={<ArrowUpwardRounded />}
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      textTransform: "uppercase",
                    }}
                  >
                    Payment Pending
                  </Button>
                </Badge>
              </Box>
            )}

          {!isLandingPage && (
            <Logo
              isLandingPage={false}
              sx={{
                gridArea: "logo",
                paddingTop: "1rem",
                justifySelf: isMobile ? "center" : "unset",
              }}
            />
          )}
          {showAllButton() && (
            <Box
              sx={{
                gridArea: "logoChip",
                pb: 1,
              }}
            >
              <Chip
                onClick={() => {
                  handleClickAll();
                }}
                variant="filter"
                label="All"
                icon={<ArrowBackOutlined />}
              />
            </Box>
          )}
          {!isLandingPage && (
            <Box
              sx={{
                position: isMobile ? "unset" : "relative",
                gridArea: "search",
                pt: isMobile ? 0 : 1,
                height: "65px",
                display: "flex",
                justifyContent: isMobile ? "center" : "start",
              }}
            >
              <PrimarySearchBox />
            </Box>
          )}

          <Collapse
            in={showFilters()}
            sx={{
              gridArea: "filter",
            }}
          >
            <Stack direction="row" pb={1}>
              <IconButton
                LinkComponent={Link}
                size="small"
                to={buildSearchUrl({ query: currentSearchTerm })}
                variant="chip"
              >
                <Close />
              </IconButton>
              {currentSource !== defaultSource.key && (
                <Chip
                  onClick={(e) => {
                    handleActiveStateChange(true);
                  }}
                  variant="filter"
                  label={getSource(currentSource).label}
                  icon={<Check />}
                />
              )}
              {currentCategory && (
                <Chip
                  onClick={(e) => {
                    handleActiveStateChange(true);
                  }}
                  variant="filter"
                  label={getCategory(currentCategory).label}
                  icon={<Check />}
                />
              )}
              {currentSubCategory && (
                <Chip
                  onClick={(e) => {
                    handleActiveStateChange(true);
                  }}
                  variant="filter"
                  label={getSubCategory(currentSubCategory).label}
                  icon={<Check />}
                />
              )}
              {currentTimeframe !== defaultTimeframe.key && (
                <Chip
                  onClick={(e) => {
                    handleActiveStateChange(true);
                  }}
                  variant="filter"
                  label={getTimeframe(currentTimeframe).label}
                  icon={<Check />}
                />
              )}
            </Stack>
          </Collapse>

          {!isMobile && (
            <Box
              sx={{
                display: "flex",
                alignSelf: "stretch",
                gridArea: "nav",
              }}
            >
              <MainNavLink
                onClick={() =>
                  logEvent("open_pulse_page", {
                    click: true,
                  })
                }
                to="/pulse"
                title="Pulse"
                isLanding={isLandingPage}
              >
                  <PulseIconLogo
                    inheritViewBox
                    sx={{
                      fill: isLandingPage
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                    }}
                  />
              </MainNavLink>

              <MainNavLink
                onClick={() =>
                  logEvent("open_collections_page", {
                    click: true,
                  })
                }
                to="/collections"
                title="Collections"
                isLanding={isLandingPage}
              >
                  <SvgIcon
                    component={CollectionWhiteLogo}
                    inheritViewBox
                    sx={{
                      fill: isLandingPage
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                    }}
                  />
              </MainNavLink>

              <MainNavLink
                to="/tools"
                title="Research Tools"
                isLanding={isLandingPage}
                onClick={handleResearchOpen}
              >
                  <SvgIcon
                    component={ResearchToolsWhite}
                    inheritViewBox
                    sx={{
                      fill: isLandingPage
                        ? theme.palette.grey[300]
                        : theme.palette.grey[700],
                    }}
                  />
              </MainNavLink>
              <MainNavLink
                onClick={() =>
                  logEvent("open_profile_page", {
                    click: true,
                  })
                }
                to="/profile"
                title="Profile"
                isLanding={isLandingPage}
              >
                {" "}
                {/* onClick={handleProfileOpen}> */}
                  <ProfileAvatar userId={user.userId} />
              </MainNavLink>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Popover
        id="research-menu"
        open={Boolean(researchPopup)}
        anchorEl={researchPopup}
        onClose={handleResearchClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "grid",
            gridTemplateColumns: "repeat(6, 5rem)",
            gap: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.grey[700],
              gridColumnStart: "span 6",
            }}
          >
            Research Tools
          </Typography>
          <ToolMenuItem
            title="Summarizer"
            link="/tools/summarizer"
            sx={{
              gridColumnStart: "span 3",
            }}
          >
            <SummarizerIcon />
          </ToolMenuItem>
          <ToolMenuItem
            title="Reading Level"
            link="/tools/reading-level"
            sx={{
              gridColumnStart: "span 3",
            }}
          >
            <AutoStoriesOutlinedIcon />
          </ToolMenuItem>
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.grey[700],
              gridColumnStart: "span 6",
            }}
          >
            Misinformation Toolkit
          </Typography>
          <ToolMenuItem
            title="Domain Checker"
            link="/tools/domain-checker"
            sx={{
              gridColumnStart: "span 2",
            }}
          >
            <DomainCheckerIcon />
          </ToolMenuItem>
          <ToolMenuItem
            title="Misinformation Fingerprints"
            link="/tools/misinformation-fingerprints"
            sx={{
              gridColumnStart: "span 2",
            }}
          >
            <MisinformationFingerprintIcon />
          </ToolMenuItem>
          <ToolMenuItem
            title="Misinformation Search"
            link="/tools/misinformation-search"
            sx={{
              gridColumnStart: "span 2",
            }}
          >
            <MisinformationSearchIcon />
          </ToolMenuItem>
        </Box>
      </Popover>

      {/* <ProfilePopOver
        id="profile-menu"
        open={Boolean(profilePopup)}
        anchorEl={profilePopup}
        onClose={handleProfileClose}
      /> */}
    </React.Fragment>
  );
}
