import { currentEnv } from "config";
import { isDev } from "config";

export const getConfig = (params) => {
  const user = localStorage.getItem("user");
  if (user) {
    return {
      headers: {
        Authorization: `Bearer ${JSON.parse(user).token}`,
      },
      params,
    };
  }
};

export const homeUrl = () => {
  if (isDev) {
    return "https://staging-app.sooth.fyi/";
  }
  return "https://app.sooth.fyi/";
};

const authBaseURL = isDev
  ? process.env.REACT_APP_AUTH_URL_DEV
  : process.env.REACT_APP_ATH_URL_PROD;
const searchBaseURL = isDev
  ? process.env.REACT_APP_SEARCH_URL_ENV
  : process.env.REACT_APP_SEARCH_URL_PROD;
const subsBaseURL = isDev
  ? `https://${currentEnv}subscriptionpricing-api.sooth.fyi`
  : "https://subscriptionpricing-api.sooth.fyi";

const summaryBaseUrl = isDev
  ? "https://dev-openai.sooth.fyi"
  : "https://openai.sooth.fyi";

export const openaiAPI = {
  getSummary: `${summaryBaseUrl}/api/getSummary`,
  articleSummary: `${summaryBaseUrl}/api/articlesummary`,
  relatedSearch: `${summaryBaseUrl}/api/relatedSearch`,
  readingLevel: `${summaryBaseUrl}/api/articleCompLevel`,
};

export const citationAPI = {
  getCitationMeta: isDev
    ? "https://dev-translation-server.sooth.fyi/web"
    : "https://translation-server-api.sooth.fyi/web",
  getCitation: isDev
    ? "https://dev-translation-server.sooth.fyi/citation"
    : "https://translation-server-api.sooth.fyi/citation",
  getCitationStyles: isDev
    ? "https://dev-translation-server.sooth.fyi/corestyles"
    : "https://translation-server-api.sooth.fyi/corestyles",
};

export const downloadArticleAPI = {
  getPdf: process.env.REACT_APP_DOWNLOAD_ARTICLE,
};

export const subscriptionsAPI = {
  getAllProducts: `${subsBaseURL}/api/Stripe/GetAllProducts`,
  getAllCoupons: `${subsBaseURL}/api/Stripe/GetAllDefaultCoupons`,
  checkPromoCode: `${subsBaseURL}/api/Stripe/CheckStripePromoCode`,
  createCheckoutSession: `${subsBaseURL}/api/Stripe/CreateCheckoutSessionForCustomer`,
  createGroupCheckoutSession: `${subsBaseURL}/api/Stripe/CreateGroupCheckoutSession`,
  expireCheckoutSession: `${subsBaseURL}/api/Stripe/ExpireCheckoutSession`,
  checkoutSessionStatus: `${subsBaseURL}/api/Stripe/CheckCheckoutSessionStatus`,
  individualSubscriptionData: `${subsBaseURL}/api/Stripe/GetIndividualSubscriptionData`,
  cancelSubscription: `${subsBaseURL}/api/Stripe/CancelSubscription`,
  createPortalSession: `${subsBaseURL}/api/Stripe/CreateCustomerPortalSession`,
  createGroupCode: `${subsBaseURL}/api/OrganizationManagement/CreateGroupCode`,
  getGroupCodes: `${subsBaseURL}/api/OrganizationManagement/GetGroupCodes`,
  joinGroupWithCode: `${subsBaseURL}/api/OrganizationManagement/JoinGroupCode`,
  getGroupUsers: `${subsBaseURL}/api/OrganizationManagement/GetOrganizationUsers`,
  deleteGroupCode: `${subsBaseURL}/api/OrganizationManagement/DeleteGroupCode`,
  getGroupData: `${subsBaseURL}/api/OrganizationManagement/GetOrganizationData`,
  createGroup: `${subsBaseURL}/api/OrganizationManagement/CreateOrganization`,
  getPriceData: `${subsBaseURL}/api/Pricing/CalculatePrice`,
  createInvoice: `${subsBaseURL}/api/QuickBooks/CreateInvoiceWorkflow`,
  getCountries: `${subsBaseURL}/api/OrganizationManagement/GetAllCountries`,
  getRegionsByCountry: `${subsBaseURL}/api/OrganizationManagement/GetStatesByCountry`,
  getGroupDomains: `${subsBaseURL}/api/OrganizationManagement/GetGroupDomain`,
  createUpdateGroupDomain: `${subsBaseURL}/api/OrganizationManagement/CreateUpdateGroupDomain`,
  deleteGroupDomain: `${subsBaseURL}/api/OrganizationManagement/DeleteGroupDomain`,
  getGroupPriceData: `${subsBaseURL}/api/Pricing/CalculateGroupPrice`,
};

export const authAPI = {
  login: `${authBaseURL}/${process.env.REACT_APP_API_LOGIN}`,
  register: `${authBaseURL}/${process.env.REACT_APP_API_REGISTER}`,
  confirmEmail: `${authBaseURL}/${process.env.REACT_APP_API_CONFIRM_EMAIL}`,
  forgotPassword: `${authBaseURL}/${process.env.REACT_APP_API_FORGOT_PASSWORD}`,
  resetPasswrd: `${authBaseURL}/${process.env.REACT_APP_API_RESET_PASSWORD}`,
  changePasswrd: `${authBaseURL}/${process.env.REACT_APP_API_CHANGE_PASSWORD}`,
  resendConfirmation: `${authBaseURL}/${process.env.REACT_APP_API_SEND_CONFIRMATION}`,
  getUsers: `${authBaseURL}/${process.env.REACT_APP_API_GET_USERS}`,
  updateUser: `${authBaseURL}/${process.env.REACT_APP_API_UPDATE_USER}`,
  createUser: `${authBaseURL}/${process.env.REACT_APP_API_CREATE_USER}`,
  deactivateUser: `${authBaseURL}/${process.env.REACT_APP_API_INACTIVE_USER}`,
  switchUser: `${authBaseURL}/${process.env.REACT_APP_API_SWITCH_USER}`,
  userInvite: `${authBaseURL}/${process.env.REACT_APP_USER_INVITE}`,
  getUserInvitationCount: `${authBaseURL}/${process.env.REACT_APP_USER_INVITE_COUNT}`,
  checkUserInvite: `${authBaseURL}/${process.env.REACT_APP_CHECK_USER_INVITE}`,
  changeEmail: `${authBaseURL}/${process.env.REACT_APP_API_CHANGE_EMAIL}`,
  verifyChangeEmail: `${authBaseURL}/${process.env.REACT_APP_API_VERIFY_CHANGE_EMAIL}`,
  updateProfile: `${authBaseURL}/${process.env.REACT_APP_API_UPDATE_PROFILE}`,
  uniqueUsername: `${authBaseURL}/${process.env.REACT_APP_API_CHECK_USERNAME_AVAILABILITY}`,
  getUserDetails: `${authBaseURL}/${process.env.REACT_APP_API_GET_DETAILS_BY_ID}`,
  notifyConfirmationEmail: `${authBaseURL}/${process.env.REACT_APP_API_NOTIFY_CONFIRM_EMAIL}`,
  googleLogin: `${authBaseURL}/${process.env.REACT_APP_API_GOOGLE_SIGN_IN}`,
  microsoftLogin: `${authBaseURL}/${process.env.REACT_APP_API_MICROSOFT_SIGN_IN}`,
  cleverLogin: `${authBaseURL}/${process.env.REACT_APP_API_CLEVER_SIGN_IN}`,
  classlinkLogin: `${authBaseURL}/${process.env.REACT_APP_API_CLASSLINK_SIGN_IN}`,
  refreshToken: `${authBaseURL}/${process.env.REACT_APP_AUTH_API_REFRESH_TOKEN}`,
  followUnFollowUser: `${authBaseURL}/api/User/FollowUnFollowUser`,
  isUserFollowingUser: `${authBaseURL}/api/User/IsUserFollowingUser`,
  updateGroupLicenseKey: `${authBaseURL}/api/Account/UpdateGroupLicenseKey`,
  // Group Admin Endpoints
  createGroupUser: `${authBaseURL}/api/OrganizationAdmin/CreateOrganizationUser`,
  updateUsersRole: `${authBaseURL}/api/OrganizationAdmin/UpdateGroupUsersRole`,
  updateUsersLicenseLevel: `${authBaseURL}/api/OrganizationAdmin/UpdateGroupUsersLicenseLevel`,
  removeUserFromGroup: `${authBaseURL}/api/OrganizationAdmin/RemoveUserFromGroup`,
  bulkUpload: `${authBaseURL}/api/OrganizationAdmin/BulkUploadUsers`,
};

export const searchAPI = {
  search: `${searchBaseURL}/${process.env.REACT_APP_API_SEARCH_URL}`,
  quickSearch: `${searchBaseURL}/${process.env.REACT_APP_API_QUICK_SEARCH}`,
  getCategories: `${searchBaseURL}/${process.env.REACT_APP_API_SIDEBAR}`,
  publisherInfo: `${searchBaseURL}/${process.env.REACT_APP_API_PUBLISHER_INFO}`,
  domainPublisherInfo: `${searchBaseURL}/${process.env.REACT_APP_API_DOMAIN_INFO}`,
  updateSidebar: `${searchBaseURL}/${process.env.REACT_APP_API_UPDATE_SIDEBAR}`,
  getCollections: `${searchBaseURL}/${process.env.REACT_APP_API_GET_COLLECTIONS}`,
  updateCollections: `${searchBaseURL}/${process.env.REACT_APP_API_ADD_UPDATE_COLLECTIONS}`,
  deleteCollections: `${searchBaseURL}/${process.env.REACT_APP_API_DELETE_COLLECTIONS}`,
  shareCollections: `${searchBaseURL}/${process.env.REACT_APP_SHARE_COLLECTIONS}`,
  sharedUserList: `${searchBaseURL}/${process.env.REACT_APP_SHARED_USER_LIST}`,
  removeSharedUser: `${searchBaseURL}/${process.env.REACT_APP_REMOVE_USER_FROM_LIST}`,
  confirmSharedCollection: `${searchBaseURL}/${process.env.REACT_APP_ADD_USER_TO_COLLECTIONS}`,
  getCollectionsSource: `${searchBaseURL}/${process.env.REACT_APP_API_GET_COLLECTION_SOURCE}`,
  deleteCollectionsSource: `${searchBaseURL}/${process.env.REACT_APP_API_DELETE_COLLECTION_SOURCE}`,
  AddUpdateCollectionsSource: `${searchBaseURL}/${process.env.REACT_APP_API_ADD_UPDATE_COLLECTION_SOURCE}`,
  feedback: `${searchBaseURL}/${process.env.REACT_APP_API_FEEDBACK}`,
  getBooks: `${searchBaseURL}/${process.env.REACT_APP_BOOK_SEARCH}`,
  getNotes: `${searchBaseURL}/${process.env.REACT_APP_API_GET_NOTES}`,
  addUpdateNotes: `${searchBaseURL}/${process.env.REACT_APP_API_ADD_UPDATE_NOTES}`,
  deleteNote: `${searchBaseURL}/${process.env.REACT_APP_API_DELETE_NOTE}`,
  searchEncylopedia: `${searchBaseURL}/${process.env.REACT_APP_API_SEARCH_ENCYCLOPEDIA}`,
  saveCitation: `${searchBaseURL}/${process.env.REACT_APP_API_SAVE_CITATION}`,
  getCitation: `${searchBaseURL}/${process.env.REACT_APP_API_GET_CITATION}`,
  getParentChainCollection: `${searchBaseURL}/${process.env.REACT_APP_GET_PARENT_CHAIN_COLLECTION}`,
  userAnalytics: `${searchBaseURL}/${process.env.REACT_APP_API_USER_ANALYTICS}`,
  searchCore: `${searchBaseURL}/${process.env.REACT_APP_API_SEARCH_CORE}`,
  searchSemantic: `${searchBaseURL}/${process.env.REACT_APP_API_SEARCH_SEMANTIC}`,
  academiaSearchList: `${searchBaseURL}/${process.env.REACT_APP_API_ACADEMIA_SEARCHLIST}`,
  academiaSearch: `${searchBaseURL}/${process.env.REACT_APP_API_ACADEMIA_SEARCH}`,
  GetAutoSuggestSearch: `${searchBaseURL}/${process.env.REACT_APP_API_GET_AUTO_SUGGESTSEARCH}`,
  GetDocumentTypes: `${searchBaseURL}/${process.env.REACT_APP_API_GET_DOCUMENT_TYPES}`,
  GetCitationDynamicData: `${searchBaseURL}/${process.env.REACT_APP_API_GET_CITATION_DYNAMIC_DATA}`,
  getCollectionSourceSUmmary: `${searchBaseURL}/${process.env.REACT_APP_API_GET_SUMMARY_FROM_BOOKMARK}`,
  getRecentCollections: `${searchBaseURL}/api/Collection/GetRecentCollections`,
  getRecentCollectionSource: `${searchBaseURL}/api/CollectionSource/GetRecentCollectionSource`,
  updateCollectionPublic: `${searchBaseURL}/api/PublicCollection/UpdateCollectionPublic`,
  getPublicCollections: `${searchBaseURL}/api/PublicCollection/GetPublicCollections`,
  getPublicCollectionsSource: `${searchBaseURL}/api/PublicCollection/GetPublicCollectionsSource`,
  getParentCollections: `${searchBaseURL}/api/Collection/GetParentCollections`,
  removeAllUsersFromSharedCollection: `${searchBaseURL}/api/Share/RemoveAllUserFromSharedCollection`,
  collectionOverView: `${searchBaseURL}/api/Collection/GetCollectionOverview`,
  publicCollectionOverView: `${searchBaseURL}/api/PublicCollection/GetPublicCollectionOverview`,
  fingerprintDetails: `https://${currentEnv}feed-api.sooth.fyi/api/MisInformationFingerprint/GetMisinformationFingerprintDocumentById`,
  getInfoDietData: `${searchBaseURL}/api/UserAnalytics/GetInfoDiet`,
  getVimeoUserVideos: `${searchBaseURL}/api/Video/user/{userId}/videos`,
  getVimeoVideo: `${searchBaseURL}/api/Video/video/{videoId}`,
  getCollectionSourceForWorkCited: `${searchBaseURL}/api/WorkCited/GetCollectionSourceForWorkCited`,
  addUpdateWorkCited: `${searchBaseURL}/api/WorkCited/AddUpdateWorkCited`,
  getWorkCited: `${searchBaseURL}/api/WorkCited/GetWorkCited`,
  getWorkCitedDocument: `${searchBaseURL}/api/WorkCited/GetWorkCitedDocument`,
  deleteWorkCited: `${searchBaseURL}/api/WorkCited/DeleteWorkCited`,
  copyPublicCollection: `${searchBaseURL}/api/PublicCollection/CopyPublicCollection`,
  getNutritionLink: `${searchBaseURL}/api/PublisherInfo/GetNutritionLink`,
  getDynamicCategories: `${searchBaseURL}/api/Search/GetDynamicSearchCategoriesRanking`,
};

export const misInformationFingerprint = {
  getMisInformationFingerprintPendingQuota: `https://${currentEnv}feed-api.sooth.fyi/api/MisInformationFingerprint/GetMisInformationFingerprintPendingQuota`,
};

export const fingerprintAPI = {
  getMisinformationFingerprintSearch: `https://${currentEnv}feed-api.sooth.fyi/api/MisInformationFingerprint/GetMisinformationFingerprintSearch`,
};

export const trafficEngagementAPI = {
  getTrafficEngagementAPI: `https://${currentEnv}feed-api.sooth.fyi/api/DomainChecker/GetTrafficEngagement`,
};

export const whoIsAPI = {
  getWhoIsAPI: `https://${currentEnv}feed-api.sooth.fyi/api/DomainChecker/GetWhoisInfo`,
};
