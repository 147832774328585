import React, { useState } from "react";
import {
  Button,
  Typography,
  Stack,
  Divider,
  Popover,
  MenuItem,
  Box,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import Treeview from "./Treeview";
import { useLocation, useParams } from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { ReactComponent as CollectionsLogo } from "assets/icons/bookmark-blue-icon.svg";
import { useSelector } from "react-redux";
import SideNavLink from "components/base/SideNavLink";
import { useDispatch } from "react-redux";
import { setIsBookmarkSidebar, setSubPopup } from "context/features/dataSlice";
import { useEffect } from "react";

export default function SideMenu({
  collectionIdArray,
  setNewPopup,
}) {
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [currentCollection, setCurrentCollection] = useState("");
  const { collections } = useSelector((state) => state.data);
  const dispatch = useDispatch();

  const theme = useTheme();

  const { pathname } = useLocation();
  const params = useParams();

  const checkRecentAndPublic = () => {
    if (pathname.includes("/collections/public")) {
      return true;
    }
    if (params.id) {
      return false;
    }
    return true;
  };

  const handlePopoverOpen = (event) => {
    if (checkRecentAndPublic()) {
      setNewPopup(true);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // const { isBookmarkSidebar } = useSelector((state) => state.data);

  const isPopoverOpen = Boolean(anchorEl);

  useEffect(() => {
    if (params.id) {
      const currentCollection = collections.filter(
        (collection) => Number(collection.collectionId) === Number(params.id)
      )[0];
      setCurrentCollection(currentCollection);
    }
  }, [params.id]);

  return (
    <React.Fragment>
      <Box sx={{ ml: 3, pt: 2 }}>
        <Stack gap={1}>
          <Button
            size="large"
            startIcon={<AddIcon />}
            onClick={handlePopoverOpen}
            sx={{
              justifyContent: "flex-start",
              fontSize: "2.3rem",
              lineHeight: "3rem",
              mr: 3,
            }}
          >
            New
          </Button>
          <Popover
            anchorEl={anchorEl}
            open={isPopoverOpen}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              transform: `translateY(10%)`,
            }}
            disableScrollLock={true}
          >
            <MenuItem
              sx={{
                display: "flex",
                gap: 2,
                p: 2,
                mt: 2,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
              onClick={() => {
                setNewPopup(true);
                handlePopoverClose();
              }}
            >
              <FolderIcon sx={{ color: "#5998e5" }} />
              <Typography variant="body1">New Collection</Typography>
            </MenuItem>
            {currentCollection != null && (
              <MenuItem
                disabled={currentLevel === 2 ? true : false}
                sx={{
                  display: "flex",
                  gap: 2,
                  p: 2,
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                }}
                onClick={() => {
                  dispatch(setSubPopup(true));
                  handlePopoverClose();
                }}
              >
                <FolderCopyIcon sx={{ color: "#5998e5" }} />

                <Typography variant="body1">
                  {`Subfolder in ${currentCollection.name}`}
                </Typography>
              </MenuItem>
            )}
            <MenuItem
              sx={{
                display: "flex",
                gap: 2,
                p: 2,
                mb: 2,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
              onClick={() => {
                // setNewBookmarkPopup(true);
                handlePopoverClose();
                dispatch(setIsBookmarkSidebar(true));
              }}
            >
              <CollectionsLogo
                alt="Collections"
                style={{
                  width: 20,
                  height: 20,
                  color: "#5998e5",
                }}
              />
              <Typography variant="body1">New External Bookmark</Typography>
            </MenuItem>
          </Popover>
          <Typography variant="h6" color="#5c6d7e" fontStyle="italic" mt={1.3}>
            Your Collections
          </Typography>
          <SideNavLink to="/collections">Recent Activity</SideNavLink>
          <Treeview
            collections={collections}
            collectionIdArray={collectionIdArray}
            setCurrentLevel={setCurrentLevel}
          />
          <Divider sx={{ width: "90%" }} />
          <Typography variant="h6" color="#5c6d7e" fontStyle="italic">
            Public Collections
          </Typography>
          <SideNavLink to="/collections/public">Explore</SideNavLink>
        </Stack>
      </Box>
    </React.Fragment>
  );
}
