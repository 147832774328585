import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPublicCollectionOverview,
  getPublicCollections,
  getPublicCollectionsSource,
  publicCollectionCopy,
} from "../services/use-public-collection.service";
import { treemapSquarify } from "d3";
import { toast } from "react-toastify";

const useCollection = (setLoading) => {
  const [publicCollections, setPublicCollections] = useState([]);
  const [publicCollectionsSource, setPublicCollectionsSource] = useState([]);

  const { user } = useSelector((state) => state.auth);
  const params = useParams();
  const navigate = useNavigate();

  const getPublicCollection = async () => {
    try {
      const data = await getPublicCollections(user);
      setPublicCollections(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const fetchPublicCollectionsSource = async (id) => {
    try {
      const data = await getPublicCollectionsSource(user, id);
      setPublicCollectionsSource(data);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePublicCollectionOverview = async (id) => {
    try {
      const data = getPublicCollectionOverview(user, id);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const handlePublicCollectionCopy = async (user , id) => {
     try {
       const private_id = await publicCollectionCopy(user, id);
        toast.success("Public Collection copied successfully!")       
       navigate(`/collections/${private_id}`);
     } catch (e) {
       console.log(e);
     }
  } 

  useEffect(() => {
    getPublicCollection();
  }, [params.id]);

  return {
    publicCollections,
    fetchPublicCollectionsSource,
    publicCollectionsSource,
    handlePublicCollectionOverview,
    setPublicCollectionsSource,
    handlePublicCollectionCopy,
  };
};

export default useCollection;
