import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { map, filter, reduce, find, orderBy, includes, some } from "lodash";
import * as yup from "yup";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
  Link,
} from "@mui/material";
import {
  ArrowBack,
  ArrowBackOutlined,
  DownloadOutlined,
} from "@mui/icons-material";

import { base64toBlob } from "utils/conversion.util";
import { toastError } from "utils/toast.util";
import { useIsMobile } from "hooks/is-mobile.hook";
import { fillParent, footerFlex, scrollingBox } from "utils/base-styles";
import PageTitle from "components/layout/PageTitle";
import Footer from "components/layout/Footer";
import {
  useGroupSubscription,
  useSubscriptions,
  useUserData,
  GroupInvoiceConfirmation,
  GroupForm,
} from "features/User";
import { prorationMonths } from "features/User/data/subscriptions.data";
import { useGroup } from "features/Group";
import { FeatureHelpVideo } from "features/Support";
import { useGeoData } from "features/GeoData";

// import { invoice as sampleInvoice } from "features/User/data/group-invoice.js";

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

let stripePromise = null;

const GROUP_PLAN_DEFAULT = "k12";

export default function GroupSignUpPage() {
  const { shape } = useTheme();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar: () => <Fragment />,
  });
  const isMobile = useIsMobile();
  const { coupons, products, checkPromoCode, expireCheckoutSession } =
    useSubscriptions();
  const { createGroupCheckoutSession, createGroupInvoice } =
    useGroupSubscription();
  const { getSubscriptionInfo, getUserInfo } = useUserData();
  const { group } = useGroup();
  const { countries, activeRegions } = useGeoData();
  const { user } = useSelector((state) => state.auth);
  const formRef = useRef();

  const schema = yup.object().shape({
    organizationName: yup.string().max(75).required().label("Group Name"),
    email: yup.string().email().required().label("Email Address"),
    fullName: yup.string().max(50).required().label("Contact Name"),
    referenceNo: yup.string().notRequired().max(50).label("P.O. Number"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Must be a valid Phone Number.")
      .max(15)
      .required()
      .label("Phone Number"),
    city: yup.string().required().max(75).label("City"),
    state: yup
      .string()
      .test(
        "valid-state",
        ({ label }) => `Must be a valid ${label}`,
        async (value, context) => {
          const { country } = context.from[0].value;
          const countryObj = find(countries, { iso3: country });
          if (countryObj != null) {
            return activeRegions.length > 1
              ? some(activeRegions, { state_code: value })
              : true;
          }
          return false;
        }
      )
      .label("State/Region"),
    country: yup
      .string()
      .test("valid-country", "Must be a valid Country", (value) => {
        return some(countries, { iso3: value });
      })
      .label("Country"),
    planTypeData: yup
      .array(
        yup.object({
          classGroupId: yup.string().required(),
          noOfLicenses: yup
            .number()
            .min(0)
            .max(500000)
            .integer()
            .label("Number of Licenses"),
          productId: yup.string().required(),
        })
      )
      .test(
        "total-license-check",
        "You must assign 1 or more license",
        (value, context) => {
          const totalLicenses = reduce(
            value,
            (sum, n) => {
              if (n.noOfLicenses == null) {
                return sum + 0;
              }
              return sum + n.noOfLicenses;
            },
            0
          );
          return totalLicenses > 0;
        }
      ),
    isProrated: yup.boolean().label("Prorate"),
    prorateMonth: yup.number().label("Proration Month"),
    paymentType: yup.string().label("Payment Method"),
    couponId: yup.string().notRequired().label("Coupon ID"),
    promoCode: yup.string().notRequired().label("PromoCode"),
    productId: yup.string().label("Product ID"),
  });

  const [loading, setLoading] = useState(false);
  const [checkoutSession, setCheckoutSession] = useState(null);
  const [previousState, setPreviousState] = useState(null);
  const [invoiceConfirm, setInvoiceConfirm] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(false);

  const pdf = useMemo(() => {
    if (invoice?.pdf == null) {
      return null;
    }
    const blob = base64toBlob(invoice.pdf);
    return URL.createObjectURL(blob);
  }, [invoice]);

  const initialState = useMemo(() => {
    if (previousState != null) {
      return { ...previousState };
    }

    const groupPlan =
      group != null ? group.planType.split("-")[1] : GROUP_PLAN_DEFAULT;

    const planTypeOptions = map(
      filter(
        products,
        (product) =>
          product.metadata.groupPlan != null &&
          product.metadata.groupPlan.toLowerCase() === groupPlan
      ),
      (product) => {
        let noOfLicenses = 0;
        if (group != null) {
          const level = find(group.licenseLevels, {
            key: product.metadata.licenseLevelKey,
          });
          if (level != null) {
            noOfLicenses = level.seats;
          }
        }
        return {
          key: product.metadata.licenseLevelKey,
          classGroupId: Number(product.metadata.classGroupId),
          label: product.name,
          productId: product.id,
          groupPlan: product.metadata.groupPlan,
          noOfLicenses,
        };
      }
    );

    const sorted = orderBy(planTypeOptions, ["classGroupId"], ["asc"]);

    if (group != null) {
      return {
        organizationName: group.name,
        email: group.contact.email,
        fullName: group.contact.name,
        referenceNo: "",
        phoneNumber: group.contact.phone,
        city: group.contact.city,
        state: group.contact.state,
        country: group.contact.country,
        planTypeData: [...sorted],
        isProrated: false,
        prorateMonth: find(prorationMonths, { default: true }).key,
        paymentType: "direct",
        couponId: null,
        promoCode: "",
        productId: "",
      };
    }

    return {
      organizationName: "",
      email: user?.email != null ? user.email : "",
      fullName: "",
      referenceNo: "",
      phoneNumber: "",
      city: "",
      state: "",
      country: "USA",
      planTypeData: [...sorted],
      isProrated: false,
      prorateMonth: find(prorationMonths, { default: true }).key,
      paymentType: "direct",
      couponId: null,
      promoCode: "",
      productId: "",
    };
  }, [user, products, group, previousState]);

  async function onFormSubmit(formData) {
    const {
      email,
      fullName,
      organizationName,
      referenceNo,
      phoneNumber,
      city,
      state,
      country,
      planTypeData,
      isProrated,
      prorateMonth,
      paymentType,
      couponId,
      productId,
    } = formData;
    setPreviousState(formData);
    setLoading(true);
    try {
      if (paymentType === "direct") {
        const session = await createGroupCheckoutSession(
          organizationName,
          fullName,
          email,
          phoneNumber,
          referenceNo,
          city,
          state,
          country,
          isProrated,
          prorateMonth,
          planTypeData,
          productId,
          couponId
        );
        setCheckoutSession(session);
      } else {
        setInvoiceConfirm(formData);
      }
    } catch (error) {
      toastError(
        "Oops! something went wrong and we couldn't process your subscription request."
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleProductSelectionBack() {
    setLoading(true);
    try {
      const success = await expireCheckoutSession(checkoutSession.id);
      if (success) {
        setCheckoutSession(null);
      } else {
        toast.error("Oops! Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error("Oops! Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitInvoice(
    emails,
    {
      organizationName,
      fullName,
      email,
      phoneNumber,
      referenceNo,
      city,
      state,
      country,
      isProrated,
      prorateMonth,
      planTypeData,
      couponId,
    }
  ) {
    setInvoiceConfirm(null);
    setInvoiceLoading(true);
    const response = await createGroupInvoice(
      organizationName,
      fullName,
      email,
      phoneNumber,
      referenceNo,
      city,
      state,
      country,
      isProrated,
      prorateMonth,
      planTypeData,
      couponId,
      emails
    );

    if (response.success) {
      setInvoice(response.data);
      await getUserInfo();
      await getSubscriptionInfo();
    } else {
      toastError(
        <Fragment>
          {response.errors.map((error, index) => (
            <Typography key={`error-${index}`}>{error}</Typography>
          ))}
        </Fragment>
      );
    }
    setInvoiceLoading(false);
  }

  useEffect(() => {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    return () => {
      stripePromise = null;
    };
  }, []);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column">
        <PageTitle>
          {group != null ? "Renew" : ""} Group Plan{" "}
          <FeatureHelpVideo
            videoId="1008154548"
            title="Tip: How do I complete this page?"
          />
        </PageTitle>
        {invoiceLoading || invoice != null ? (
          invoiceLoading ? (
            <Fragment>
              <Typography>Hang on while we create your invoice.</Typography>
              <LinearProgress />
            </Fragment>
          ) : (
            <Fragment>
              <Typography>Thank you for subscribing to Sooth!</Typography>
              <Typography>
                You have chosen to pay via an invoice. Your invoice will be sent
                to the emails provided and can also be downloaded below for your
                convenience.
              </Typography>
              <Alert severity="info">
                <Typography>
                  NOTE: Please ensure payment has been completed before
                  your expiration date or your users may lose access to the
                  Sooth platform.
                </Typography>
              </Alert>
              <Stack direction="row">
                <Button
                  LinkComponent={NavLink}
                  to="/profile/subscription"
                  startIcon={<ArrowBackOutlined />}
                >
                  Go to Subscription
                </Button>
                <Button
                  startIcon={<DownloadOutlined />}
                  component="a"
                  download={`sooth-group-invoice-${invoice.invoice.docNumber}.pdf`}
                  href={`data:application/pdf;base64,${invoice.pdf}`}
                  target="_blank"
                >
                  Download Invoice
                </Button>
              </Stack>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                <Box
                  sx={{
                    height: "750px",
                  }}
                >
                  <Viewer
                    fileUrl={pdf}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </Box>
              </Worker>
            </Fragment>
          )
        ) : (
          <Fragment>
            <Typography>
              You are purchasing a Group Plan which is intended for more than
              one user (small classes, schools, districts, etc.)
            </Typography>
            {checkoutSession == null ? (
              <Formik
                initialValues={initialState}
                enableReinitialize={true}
                validationSchema={schema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={onFormSubmit}
                innerRef={formRef}
              >
                {(props) => {
                  const {
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  } = props;
                  return (
                    <GroupForm
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      isValid={isValid}
                      dirty={dirty}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      user={user}
                      loading={loading}
                      products={products}
                      coupons={coupons}
                      checkPromoCode={checkPromoCode}
                      group={group}
                      initialSubLevel={
                        group != null
                          ? group.planType.split("-")[1].toLowerCase()
                          : GROUP_PLAN_DEFAULT
                      }
                    />
                  );
                }}
              </Formik>
            ) : (
              <Stack direction={isMobile ? "column" : "row"}>
                <Box
                  sx={{
                    flex: 1,
                    justifySelf: "start",
                  }}
                >
                  <Button
                    startIcon={<ArrowBack />}
                    variant="text"
                    onClick={handleProductSelectionBack}
                  >
                    Back
                  </Button>
                </Box>
                <Box
                  sx={{
                    flex: "auto",
                    borderRadius: shape.borderRadius / 4,
                    overflow: "hidden",
                  }}
                >
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{
                      clientSecret: checkoutSession.clientSecret,
                    }}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                </Box>
              </Stack>
            )}
          </Fragment>
        )}
      </Stack>
      <GroupInvoiceConfirmation
        formData={invoiceConfirm}
        handleClose={() => setInvoiceConfirm(null)}
        handleSubmit={(emails, formData) =>
          handleSubmitInvoice(emails, formData)
        }
      />
      <Footer />
    </Box>
  );
}
